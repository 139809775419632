import type { Auth0VueClient, LogoutOptions } from '@auth0/auth0-vue'
import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'

import type { FeatureAvailability, Tenant } from '~/types/tenant'
import type { CurrentUser } from '~/types/user'

interface LinkAuth0Params {
  tenantId: string
  saml: boolean
  invitationId: string | null
}

interface LinkAuth0AssistantParams {
  tenantId: string
  assistantId: string
}

/**
 * セッション情報を管理するストア
 * memo: actionsでauth0を引数に取っているのは、auth0-vueの返すAuth0クライアントが必要なため。
 * auth0-vueのuseAuth0はコンポーネントからしか呼べない。
 * 自作composableのuseAuth0Pluginを使うと、コンポーネント外からでもAuth0クライアントを取得できるようになるが、サインインで使うと/auth/callbackでエラーになってしまう。
 */
export const useSessionStore = defineStore('session', {
  state: () => {
    return {
      user: null as CurrentUser | null,
      tenant: null as Tenant | null,
      featureAvailability: null as FeatureAvailability | null,
    }
  },
  getters: {
    isAuthenticated: (state) => !!state.user && !!state.tenant,
    isUserAdmin: (state) => {
      return !!state.user && state.user.permissions.includes('admin:user')
    },
    isTeamAdmin: (state) => {
      return !!state.user && state.user.permissions.includes('admin:team')
    },
    isRoleAdmin: (state) => {
      return !!state.user && state.user.permissions.includes('admin:role')
    },
    isBillingAdmin: (state) => {
      return !!state.user && state.user.permissions.includes('admin:billing')
    },
    isTicketWriteAdmin: (state) => {
      return (
        !!state.user && state.user.permissions.includes('admin:ticket:write')
      )
    },
    isWorkflowAdmin: (state) => {
      return !!state.user && state.user.permissions.includes('admin:workflow')
    },
    isConnectionAdmin: (state) => {
      return (
        !!state.user && state.user.permissions.includes('admin:integration')
      )
    },
    isTenantAdmin: (state) => {
      return !!state.user && state.user.permissions.includes('admin:tenant')
    },
    isSecurityAdmin: (state) => {
      return !!state.user && state.user.permissions.includes('admin:security')
    },
    isAuditAdmin: (state) => {
      return !!state.user && state.user.permissions.includes('admin:audit')
    },
    isMasterAdmin: (state) => {
      return !!state.user && state.user.permissions.includes('admin:master')
    },
    isLabelAdmin: (state) => {
      return !!state.user && state.user.permissions.includes('admin:label')
    },
    isStatsAdmin: (state) => {
      return !!state.user && state.user.permissions.includes('admin:stats')
    },
    isAutomationAdmin: (state) => {
      return !!state.user && state.user.permissions.includes('admin:automation')
    },
    isAdmin: (state) => {
      return !!state.user && state.user.permissions.length > 0
    },
    isTrial: (state) => {
      return state.tenant?.pricingPlanPublicId === 'trial'
    },
    isStandardOrUp: (state) => {
      if (!state.tenant) {
        return false
      }
      return ['standard', 'enterprise', 'trial'].includes(
        state.tenant!.pricingPlanPublicId
      )
    },
    isEnterpriseOrUp: (state) => {
      if (!state.tenant) {
        return false
      }
      return ['enterprise', 'trial'].includes(state.tenant!.pricingPlanPublicId)
    },
    ssoAvailable: (state) => {
      return (
        !!state.tenant &&
        !!state.featureAvailability &&
        state.featureAvailability.sso
      )
    },
    isTrialExpired: (state) => {
      return !!state.tenant && state.tenant.pricingPlanPublicId === 'free'
    },
  },
  actions: {
    setUser(newUser: CurrentUser) {
      this.user = newUser

      //memo: useI18nはsetupの中でしか呼び出せない
      const i18n = useNuxtApp().$i18n
      i18n.setLocaleCookie(newUser.locale)

      Sentry.setUser({ id: newUser.id, email: newUser.email })
    },
    setTenant(newTenant: Tenant) {
      this.tenant = newTenant
    },
    setFeatureAvailability(newFeatureAvailability: FeatureAvailability) {
      this.featureAvailability = newFeatureAvailability
    },
    /**
     * ログアウト処理
     * @param auth0 Auth0VueClient
     * @param returnTo ログアウト後の遷移先
     */
    async logOut(auth0: Auth0VueClient, returnTo: string) {
      const nuxtApp = useNuxtApp()
      const consola = useConsola()

      await this.deleteFcmToken()

      consola.debug('***')
      consola.debug(nuxtApp)
      consola.debug(nuxtApp.vueApp)
      consola.debug(this)

      consola.debug('logOut: returnTo = ' + returnTo)
      const options: LogoutOptions = {
        logoutParams: {
          returnTo,
        },
      }
      await auth0.logout(options)
      nuxtApp.$httpClient.setHeader('X-Authorization', undefined)
    },
    /**
     * ユーザー情報とテナント情報を並列でリフレッシュする
     * @returns {Promise<void>}
     */
    async refreshUserData() {
      const nuxtApp = useNuxtApp()

      const requests = []

      requests.push(
        (async () => {
          const user = await nuxtApp.$kickflowUserApi.getCurrentUser()
          this.setUser(user)
        })()
      )

      requests.push(
        (async () => {
          const tenant = await nuxtApp.$kickflowTenantApi.getCurrentTenant()
          this.setTenant(tenant)
        })()
      )

      return Promise.all(requests)
    },
    async signUpForFirstAdmin(auth0: Auth0VueClient) {
      const nuxtApp = useNuxtApp()
      const commonUiStore = useCommonUiStore()

      try {
        // この時点ではまだsetHeaderしてないので、個別にトークンをセットする
        const token = await auth0.getAccessTokenSilently()
        const user = await nuxtApp.$kickflowAuthApi.signUpWithIdToken(
          token,
          auth0.idTokenClaims.value!
        )
        this.setUser(user)
        nuxtApp.$httpClient.setHeader('X-Authorization', 'Bearer ' + token)
        await this.refreshUserData()
        return true
      } catch (e) {
        commonUiStore.showErrorSnackbar(e)
        return false
      }
    },
    async linkAuth0Later(auth0: Auth0VueClient) {
      const nuxtApp = useNuxtApp()
      const commonUiStore = useCommonUiStore()

      try {
        // この時点ではまだsetHeaderしてないので、個別にトークンをセットする
        const token = await auth0.getAccessTokenSilently()
        const user = await nuxtApp.$kickflowAuthApi.linkAuth0Later(
          token,
          auth0.idTokenClaims.value!
        )
        this.setUser(user)
        nuxtApp.$httpClient.setHeader('X-Authorization', 'Bearer ' + token)
        await this.refreshUserData()
        return true
      } catch (e) {
        commonUiStore.showErrorSnackbar(e)
        return false
      }
    },
    async signIn(auth0: Auth0VueClient) {
      const nuxtApp = useNuxtApp()
      const commonUiStore = useCommonUiStore()

      try {
        // この時点ではまだsetHeaderしてないので、個別にトークンをセットする
        const token = await auth0.getAccessTokenSilently()
        nuxtApp.$httpClient.setHeader('X-Authorization', 'Bearer ' + token)
        await this.refreshUserData()
        return true
      } catch (e) {
        commonUiStore.showErrorSnackbar(e)
        return false
      }
    },
    async linkAuth0(auth0: Auth0VueClient, params: LinkAuth0Params) {
      const nuxtApp = useNuxtApp()
      const commonUiStore = useCommonUiStore()

      try {
        // この時点ではまだsetHeaderしてないので、個別にトークンをセットする
        const token = await auth0.getAccessTokenSilently()
        const claim = auth0.idTokenClaims
        let user
        if (params.saml) {
          user = await nuxtApp.$kickflowAuthApi.linkAuth0Saml(
            token,
            claim.value!
          )
        } else {
          user = await nuxtApp.$kickflowAuthApi.linkAuth0(
            token,
            claim.value!,
            params.invitationId!
          )
        }
        this.setUser(user)

        nuxtApp.$httpClient.setHeader('X-Authorization', 'Bearer ' + token)

        await this.refreshUserData()

        return true
      } catch (e) {
        commonUiStore.showErrorSnackbar(e)
        return false
      }
    },
    async linkAuth0Assistant(
      auth0: Auth0VueClient,
      params: LinkAuth0AssistantParams
    ) {
      const nuxtApp = useNuxtApp()
      const commonUiStore = useCommonUiStore()

      try {
        // この時点ではまだsetHeaderしてないので、個別にトークンをセットする
        const token = await auth0.getAccessTokenSilently()
        const claim = auth0.idTokenClaims
        const user = await nuxtApp.$kickflowAuthApi.linkAuth0Assistant(
          token,
          claim.value!,
          params.assistantId
        )
        this.setUser(user)
        nuxtApp.$httpClient.setHeader('X-Authorization', 'Bearer ' + token)
        await this.refreshUserData()
        return true
      } catch (e) {
        commonUiStore.showErrorSnackbar(e)
        return false
      }
    },
    /**
     * FCMのトークンを一度削除し、再度送信し直す
     */
    async resendFcmToken() {
      const nuxtApp = useNuxtApp()
      const firebase = useFirebase()

      try {
        if (import.meta.client) {
          const supported = await firebase.isMessagingSupported()
          if (supported) {
            if (Notification.permission === 'granted') {
              await firebase.getToken()
              await firebase.deleteToken()
              const token = await firebase.getToken()
              await nuxtApp.$kickflowFcmTokenApi.sendFcmToken(
                token,
                navigator.userAgent
              )
            }
          }
        }
      } catch {
        // do nothing
      }
    },
    /**
     * FCMのトークンを削除する
     */
    async deleteFcmToken() {
      const firebase = useFirebase()

      try {
        if (import.meta.client) {
          const supported = await firebase.isMessagingSupported()
          if (supported) {
            if (Notification.permission === 'granted') {
              await firebase.getToken()
              await firebase.deleteToken()
            }
          }
        }
      } catch {
        // do nothing
      }
    },
  },
})
